@use '../../reference';

.link {
  align-items: center;
  color: reference.$color-primary;
  display: inline-flex;
  font-size: 14px;
  font-weight: reference.$font-weight-bold;
  gap: 0.5rem;
  text-decoration: none;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  svg {
    color: reference.$color-primary;
    transition: transform 250ms ease-in-out;
  }

  &:focus,
  &:hover {
    svg {
      transform: translateX(0.25rem);
    }
  }
}
