$-color-primary: #a71930;
$color-primary: var(--color-primary);

$-color-primary-25: rgba($-color-primary, 0.25);
$color-primary-25: var(--color-primary-25);

$-color-primary-dark: #a9172d;
$color-primary-dark: var(--color-primary-dark);

$-color-aqua: #6fafb7;
$color-aqua: var(--color-aqua);

$-color-aqua-10: #eef5f7;
$color-aqua-10: var(--color-aqua-10);

$-color-fuchsia: #e23d95;
$color-fuchsia: var(--color-fuchsia);

$-color-fuchsia-10: #fcecf4;
$color-fuchsia-10: var(--color-fuchsia-10);

$-color-new-leaf: #a1af00;
$color-new-leaf: var(--color-new-leaf);

$-color-new-leaf-10: #fbfce6;
$color-new-leaf-10: var(--color-new-leaf-10);

$-color-tangerine: #f26522;
$color-tangerine: var(--color-tangerine);

$-color-tangerine-10: #fef0e6;
$color-tangerine-10: var(--color-tangerine-10);

$-color-white: #fff;
$color-white: var(--color-white);

$-color-grey-100: #f5f5f5;
$color-grey-100: var(--color-grey-100);

$-color-grey-200: #d9d9d9;
$color-grey-200: var(--color-grey-200);

$-color-grey-300: #b0b7bc;
$color-grey-300: var(--color-grey-300);

$-color-grey-400: #455560;
$color-grey-400: var(--color-grey-400);

$-color-grey-500: #293339;
$color-grey-500: var(--color-grey-500);

$-color-grey-600: #121212;
$color-grey-600: var(--color-grey-600);

$-color-black: #000;
$color-black: var(--color-black);

$-font-weight-light: 300;
$font-weight-light: var(--font-weight-light);

$-font-weight-normal: 400;
$font-weight-normal: var(--font-weight-normal);

$-font-weight-semibold: 600;
$font-weight-semibold: var(--font-weight-semibold);

$-font-weight-bold: 700;
$font-weight-bold: var(--font-weight-bold);

$-font-size-heading-1: clamp(2.375rem, 3vw, 3.5rem);
$font-size-heading-1: var(--font-size-heading-1);

$-font-size-heading-2: clamp(1.75rem, 2.1875vw, 2.625rem);
$font-size-heading-2: var(--font-size-heading-2);

$-font-size-heading-3: clamp(1.5rem, 1.875vw, 2.25rem);
$font-size-heading-3: var(--font-size-heading-3);

$-font-size-heading-4: clamp(1.375rem, 1.25vw, 1.5rem);
$font-size-heading-4: var(--font-size-heading-4);

$-font-size-heading-5: clamp(1.25rem, 1.15vw, 1.375rem);
$font-size-heading-5: var(--font-size-heading-5);

$-font-size-body-large: clamp(1.125rem, 1.15vw, 1.375rem);
$font-size-body-large: var(--font-size-body-large);

$-font-size-body: clamp(1rem, 0.9375vw, 1.125rem);
$font-size-body: var(--font-size-body);

$-font-size-button: clamp(0.875rem, 0.9375vw, 1.125rem);
$font-size-button: var(--font-size-button);

$-font-size-filter: 0.875rem;
$font-size-filter: var(--font-size-filter);

$-font-size-huge: clamp(3.42rem, 2.7074rem + 3.563vw, 5.4688rem);
$font-size-huge: var(--font-size-huge);

$-font-size-person: clamp(0.875rem, 0.8333vw, 1rem);
$font-size-person: var(--font-size-person);

$-font-size-tab: 0.875rem;
$font-size-tab: var(--font-size-tab);

$-font-size-tag: 0.875rem;
$font-size-tag: var(--font-size-tag);

$-border-radius: 0.75rem;
$border-radius: var(--border-radius);

$-box-shadow-1: 0px 4px 18px rgba(0, 0, 0, 0.17);
$box-shadow-1: var(--box-shadow-1);

$breakpoint-sm: 40rem;
$breakpoint-md: 48rem;
$breakpoint-lg: 64rem;
$breakpoint-xl: 80rem;
$breakpoint-2xl: 96rem;

$-enable-dark-mode: false;

$-space-3xs: clamp(0.25rem, 0.2065rem + 0.2174vw, 0.375rem);
$space-3xs: var(--space-3xs);

$-space-2xs: clamp(0.5rem, 0.413rem + 0.4348vw, 0.75rem);
$space-2xs: var(--space-2xs);

$-space-xs: clamp(0.75rem, 0.6196rem + 0.6522vw, 1.125rem);
$space-xs: var(--space-xs);

$-space-s: clamp(1rem, 0.8261rem + 0.8696vw, 1.5rem);
$space-s: var(--space-s);

$-space-m: clamp(1.5rem, 1.2391rem + 1.3043vw, 2.25rem);
$space-m: var(--space-m);

$-space-l: clamp(2rem, 1.6522rem + 1.7391vw, 3rem);
$space-l: var(--space-l);

$-space-xl: clamp(3rem, 2.4783rem + 2.6087vw, 4.5rem);
$space-xl: var(--space-xl);

$-space-2xl: clamp(4rem, 3.3043rem + 3.4783vw, 6rem);
$space-2xl: var(--space-2xl);

$-space-3xl: clamp(6rem, 4.9565rem + 5.2174vw, 9rem);
$space-3xl: var(--space-3xl);

$-transition-duration-short: 150ms;
$transition-duration-short: var(--transition-duration-short);

$-transition-duration-medium: 250ms;
$transition-duration-medium: var(--transition-duration-medium);

$-transition-duration-long: 400ms;
$transition-duration-long: var(--transition-duration-long);

$-width-readable: 54rem;
$width-readable: var(--width-readable);

@mixin dark-mode {
  @if ($-enable-dark-mode) {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }
}

@mixin root {
  :root {
    --color-primary: #{$-color-primary};
    --color-primary-25: #{$-color-primary-25};
    --color-primary-dark: #{$-color-primary-dark};

    --color-aqua: #{$-color-aqua};
    --color-aqua-10: #{$-color-aqua-10};

    --color-fuchsia: #{$-color-fuchsia};
    --color-fuchsia-10: #{$-color-fuchsia-10};

    --color-new-leaf: #{$-color-new-leaf};
    --color-new-leaf-10: #{$-color-new-leaf-10};

    --color-tangerine: #{$-color-tangerine};
    --color-tangerine-10: #{$-color-tangerine-10};

    --color-white: #{$-color-white};
    --color-grey-100: #{$-color-grey-100};
    --color-grey-200: #{$-color-grey-200};
    --color-grey-300: #{$-color-grey-300};
    --color-grey-400: #{$-color-grey-400};
    --color-grey-500: #{$-color-grey-500};
    --color-grey-600: #{$-color-grey-600};
    --color-black: #{$-color-black};

    --font-weight-light: #{$-font-weight-light};
    --font-weight-normal: #{$-font-weight-normal};
    --font-weight-semibold: #{$-font-weight-semibold};
    --font-weight-bold: #{$-font-weight-bold};

    --font-size-heading-1: #{$-font-size-heading-1};
    --font-size-heading-2: #{$-font-size-heading-2};
    --font-size-heading-3: #{$-font-size-heading-3};
    --font-size-heading-4: #{$-font-size-heading-4};
    --font-size-heading-5: #{$-font-size-heading-5};
    --font-size-body-large: #{$-font-size-body-large};
    --font-size-body: #{$-font-size-body};
    --font-size-button: #{$-font-size-button};
    --font-size-filter: #{$-font-size-filter};
    --font-size-huge: #{$-font-size-huge};
    --font-size-person: #{$-font-size-person};
    --font-size-tab: #{$-font-size-tab};
    --font-size-tag: #{$-font-size-tag};

    --border-radius: #{$-border-radius};

    --box-shadow-1: #{$-box-shadow-1};

    --space-3xs: #{$-space-3xs};
    --space-2xs: #{$-space-2xs};
    --space-xs: #{$-space-xs};
    --space-s: #{$-space-s};
    --space-m: #{$-space-m};
    --space-l: #{$-space-l};
    --space-xl: #{$-space-xl};
    --space-2xl: #{$-space-2xl};
    --space-3xl: #{$-space-3xl};

    --transition-duration-short: #{$-transition-duration-short};
    --transition-duration-medium: #{$-transition-duration-medium};
    --transition-duration-long: #{$-transition-duration-long};

    --width-readable: #{$-width-readable};

    --share-and-connect: #{$color-fuchsia-10};
    --reflect-and-align: #{$color-aqua-10};
    --engage-and-collaborate: #{$color-tangerine-10};
    --care-and-support: #{$color-new-leaf-10};

    @include dark-mode {
      --color-primary: #e2405b;
      --color-white: #{$-color-black};
      --color-grey-100: #{$-color-grey-600};
      --color-grey-200: #{$-color-grey-500};
      --color-grey-300: #{$-color-grey-400};
      --color-grey-400: #{$-color-grey-300};
      --color-grey-500: #{$-color-grey-200};
      --color-grey-600: #{$-color-grey-100};

      --color-fuchsia-10: hsl(330deg 75% 25%);
      --color-aqua-10: hsl(193deg 36% 25%);
      --color-tangerine-10: hsl(25deg 92% 25%);
      --color-new-leaf-10: hsl(63deg 78% 25%);

      --box-shadow-1: 0px 4px 18px rgba(255, 255, 255, 0.17);
    }
  }
}

@mixin container($width: 82rem) {
  margin-inline: auto;
  max-width: calc(100vw - 4rem);
  width: $width;
}

@mixin flex-column($gap: $space-2xs) {
  display: flex;
  flex-direction: column;
  gap: $gap;

  & > * {
    margin-block: 0;
  }
}

@mixin grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @container (min-width: 60rem) {
    display: grid;
  }
}

@mixin readable-width {
  max-width: $width-readable;
}

@mixin button {
  @include type-button;

  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 2.5rem;
  color: $color-white;
  padding-block: 0.875em;
  padding-inline: 1.5em;
  transition-property: background-color, color;
  text-align: center;
  text-decoration: none;
  width: fit-content;

  &:focus-visible,
  &:hover {
    background-color: $color-white;
    color: $color-primary;
  }
}

@mixin card {
  border: 1px solid $color-grey-100;
  border-radius: reference.$border-radius;
  box-sizing: border-box;
  padding: 1.5rem;
}

@mixin type-heading-1 {
  color: $color-primary;
  font-size: $font-size-heading-1;
  font-weight: $font-weight-bold;
  line-height: 1;
}

@mixin type-heading-2 {
  color: $color-grey-500;
  font-size: $font-size-heading-2;
  font-weight: $font-weight-bold;
  line-height: 1;
}

@mixin type-heading-3 {
  color: $color-grey-500;
  font-size: $font-size-heading-3;
  font-weight: $font-weight-bold;
  line-height: 1;
}

@mixin type-heading-4 {
  color: $color-grey-500;
  font-size: $font-size-heading-4;
  font-weight: $font-weight-bold;
  line-height: 1;
}

@mixin type-heading-5 {
  color: $color-grey-500;
  font-size: $font-size-heading-5;
  font-weight: $font-weight-bold;
  line-height: 1;
}

@mixin type-body-large {
  font-size: $font-size-body-large;
  line-height: 1.5;
}

@mixin type-body {
  font-size: $font-size-body;
  line-height: 1.5;
}

@mixin type-button {
  font-size: $font-size-button;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
}

@mixin type-lead-in {
  font-size: $font-size-body-large;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

@mixin type-person {
  font-size: $font-size-person;
}

@mixin type-tab {
  font-size: $font-size-tab;
  font-weight: $font-weight-semibold;
}

@mixin type-tag {
  font-size: $font-size-tag;
  font-weight: $font-weight-semibold;
}
